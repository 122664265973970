import axios from 'axios'

export const checkAuthMiddleware = async (to, from, next) => {
  const token = localStorage.getItem('access_token')

  try {
    await axios.get('http://localhost:3000/verify-token/', {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
    if (to.name === 'auth.login') {
      next({ name: 'default.start' })
    } else {
      next()
    }
  } catch (error) {
    console.error('Erro ao verificar o token:', error)
    if (to.name !== 'auth.login') {
      next({ name: 'auth.login' })
    } else {
      next()
    }
  }
}
