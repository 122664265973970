import { createRouter, createWebHistory } from 'vue-router'
import { checkAuthMiddleware } from '@/middlewares/middlewareAuth'

// Auth Default Routes
const authChildRoutes = (prefix) => [
  {
    path: 'login',
    name: prefix + '.login',
    meta: { auth: false, name: 'Login' },
    component: () => import('@/views/auth/SignIn.vue')
  },
  {
    path: 'reset-password',
    name: prefix + '.reset-password',
    meta: { auth: false, name: 'Reset Password' },
    component: () => import('@/views/auth/ResetPassword.vue')
  }
]

//Landing pages route
const landingPageRoutes = (prefix) => [
  {
    path: '',
    name: prefix + 'landing-page',
    meta: { auth: true, name: 'Landing Page', isBanner: true, header: 'header_two', footer: 'footer' },
    component: () => import('@/views/modules/landing-pages/HomePage.vue')
  },
  {
    path: '/about-us',
    name: prefix + 'about-us',
    meta: { auth: true, name: 'About Us', isBanner: true, header: 'header_one', footer: 'footer' },
    component: () => import('@/views/modules/landing-pages/AboutUs.vue')
  },
  {
    path: '/blogs',
    name: prefix + 'blogs',
    meta: { auth: true, name: 'Blogs', isBanner: true, header: 'header_one', footer: 'footer' },
    component: () => import('@/views/modules/landing-pages/BlogPage')
  },
  {
    path: '/blog-details',
    name: prefix + 'blog-details',
    meta: { auth: true, name: 'Blogs', isBanner: true, header: 'header_one', footer: 'footer' },
    component: () => import('@/views/modules/landing-pages/BlogDetail')
  },
  {
    path: '/contact-us',
    name: prefix + 'contact-us',
    meta: { auth: true, name: 'Contact Us', isBanner: true, header: 'header_one', footer: 'footer' },
    component: () => import('@/views/modules/landing-pages/ContactUs')
  },
  {
    path: '/e-commerce',
    name: prefix + 'e-commerce',
    meta: { auth: true, name: 'E-Commerce', isBanner: true, header: 'header_one', footer: 'footer' },
    component: () => import('@/views/modules/landing-pages/ECommerce.vue')
  },
  {
    path: '/shop',
    name: prefix + 'shop',
    meta: { auth: true, name: 'Shop', isBanner: true, header: 'header_one', footer: 'footer' },
    component: () => import('@/views/modules/landing-pages/ShopPage')
  },
  {
    path: '/shop-detail',
    name: prefix + 'shop-detail',
    meta: { auth: true, name: 'Shop Detail', isBanner: true, header: 'header_one', footer: 'footer' },
    component: () => import('@/views/modules/landing-pages/ShopDetails')
  },
  {
    path: '/faq',
    name: prefix + 'faq',
    meta: { auth: true, name: 'FAQ', isBanner: true, header: 'header_one', footer: 'footer' },
    component: () => import('@/views/modules/landing-pages/FAQ')
  },
  {
    path: '/features',
    name: prefix + 'features',
    meta: { auth: true, name: 'Features', isBanner: true, header: 'header_one', footer: 'footer' },
    component: () => import('@/views/modules/landing-pages/FeaturesPage.vue')
  },
  {
    path: '/landing-pricing',
    name: prefix + 'pricing',
    meta: { auth: true, name: 'Pricing', isBanner: true, header: 'header_one', footer: 'footer' },
    component: () => import('@/views/modules/landing-pages/PricingPage')
  },
  {
    path: '/saas-marketing',
    name: prefix + 'sass-marketing',
    meta: { auth: true, name: 'Sass Marketing', isBanner: true, header: 'header_one', footer: 'footer' },
    component: () => import('@/views/modules/landing-pages/SassMarketingLandingPage.vue')
  },
  {
    path: '/software',
    name: prefix + 'software',
    meta: { auth: true, name: 'Software Landing Page', isBanner: true, header: 'header_one', footer: 'footer-one' },
    component: () => import('@/views/modules/landing-pages/SoftwareLandingPage.vue')
  },
  {
    path: '/start-up',
    name: prefix + 'start-up',
    meta: { auth: true, name: 'Start Up', isBanner: true, header: 'header_one', footer: 'footer_one' },
    component: () => import('@/views/modules/landing-pages/StartupLandingPage')
  }
]

// Default routes
const defaultChildRoutes = (prefix) => [
  {
    path: '',
    name: prefix + '.start',
    meta: { auth: true, name: 'Start', isBanner: false },
    component: () => import('@/views/index/IndexPage.vue')
  },
  {
    path: '/companies',
    name: 'companies',
    meta: { auth: true, name: 'Cadastro de empresa', isBanner: false },
    component: () => import('@/views/companies/CreateCompanies.vue')
  },
  {
    path: '/search-companies',
    name: 'viewcompanies',
    meta: { auth: true, name: 'viewcompanies', isBanner: false },
    component: () => import('@/views/companies/CompaniesList.vue')
  },
  {
    path: '/companies/:id',
    name: 'companiesupdate',
    meta: { auth: true, name: 'companiesupdate', isBanner: false },
    component: () => import('@/views/companies/CreateCompanies.vue')
  },
  {
    path: '/users',
    name: 'users',
    meta: { auth: true, name: 'Users', isBanner: false },
    component: () => import('@/views/users/CreateUsers.vue')
  },
  {
    path: '/search-users',
    name: 'viewUsers',
    meta: { auth: true, name: 'viewUsers', isBanner: false },
    component: () => import('@/views/users/UsersList.vue')
  },
  {
    path: '/vehicles',
    name: 'vehicles',
    meta: { auth: true, name: 'vehicles', isBanner: false },
    component: () => import('@/views/vehicles/CreateVehicles.vue')
  },
  {
    path: '/search-vehicles',
    name: 'viewVehicles',
    meta: { auth: true, name: 'viewVehicles', isBanner: false },
    component: () => import('@/views/vehicles/VehiclesList.vue')
  },
  {
    path: '/show-vehicle/:id',
    name: 'showVehicle',
    meta: { auth: true, name: 'showVehicle', isBanner: false },
    component: () => import('@/views/vehicles/ShowVehicle.vue')
  },
  {
    path: '/drivers',
    name: 'drivers',
    meta: { auth: true, name: 'drivers', isBanner: false },
    component: () => import('@/views/drivers/CreateDrivers.vue')
  },
  {
    path: '/search-drivers',
    name: 'viewDrivers',
    meta: { auth: true, name: 'viewDrivers', isBanner: false },
    component: () => import('@/views/drivers/DriversList.vue')
  },
  {
    path: '/debts',
    name: 'debts',
    meta: { auth: true, name: 'debts', isBanner: false },
    component: () => import('@/views/debts/CreateDebts.vue')
  },
  {
    path: '/search-debts',
    name: 'viewDebts',
    meta: { auth: true, name: 'viewDebts', isBanner: false },
    component: () => import('@/views/debts/DebtsList.vue')
  },
  {
    path: '/value-histories',
    name: 'valueHistories',
    meta: { auth: true, name: 'valueHistories', isBanner: false },
    component: () => import('@/views/valueHistories/CreateValueHistories.vue')
  },
  {
    path: '/blank-page',
    name: prefix + '.blank-page',
    meta: { auth: true, name: 'Blank Page', isBanner: true },
    component: () => import('@/views/BlankPage.vue')
  },
  // Extra Pages
  {
    path: '/privacy-policy',
    name: prefix + '.privacy-policy',
    meta: { auth: true, name: 'Privacy Policy', isBanner: true },
    component: () => import('@/views/extra/PrivacyPolicy.vue')
  },
  {
    path: '/terms-and-conditions',
    name: prefix + '.terms-and-conditions',
    meta: { auth: true, name: 'Terms and Conditions', isBanner: true },
    component: () => import('@/views/extra/TermsAndConditions.vue')
  }
]

const errorRoutes = (prefix) => [
  // Error Pages
  {
    path: '404',
    name: prefix + '.404',
    meta: { auth: true, name: 'Error 404', isBanner: true },
    component: () => import('@/views/errors/Error404Page.vue')
  },
  {
    path: '500',
    name: prefix + '.500',
    meta: { auth: true, name: 'Error 500', isBanner: true },
    component: () => import('@/views/errors/Error500Page.vue')
  },
  {
    path: 'maintenance',
    name: prefix + '.maintenance',
    meta: { auth: true, name: 'Maintenance', isBanner: true },
    component: () => import('@/views/errors/MaintenancePage.vue')
  }
]

const routes = [
  // Default Pages
  {
    path: '/',
    name: 'default',
    component: () => import('../layouts/DefaultLayout.vue'),
    children: defaultChildRoutes('default')
  },

  //landing pages
  {
    path: '/landing-page',
    name: 'landing-page',
    component: () => import('../layouts/LandingPageLayout.vue'),
    children: landingPageRoutes('landing-page')
  },

  // Auth Skins
  {
    path: '/auth',
    name: 'auth',
    component: () => import('../layouts/guest/BlankLayout.vue'),
    children: authChildRoutes('auth')
  },
  // Errors Pages
  {
    path: '/errors',
    name: 'errors',
    component: () => import('../layouts/guest/BlankLayout.vue'),
    children: errorRoutes('errors')
  },
  { path: '/:pathMatch(.*)*', name: 'not-found', component: () => import('@/views/errors/Error404Page.vue') }
]

const router = createRouter({
  linkActiveClass: 'active',
  linkExactActiveClass: 'exact-active',
  history: createWebHistory(process.env.BASE_URL),
  base: process.env.BASE_URL,
  routes
})
router.beforeEach(checkAuthMiddleware)


export default router
